import { Col, InputNumber, Row, Table } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { apphubcalculator } from "../../api/accountAction";
import preloader from "../../../assets/loader/pre_loader.gif";

const TokenCalculator = (props) => {
  const [calcRecord, setCalcRecord] = useState([]);
  const [pagecalcRecord, setPageCalcRecord] = useState([]);
  const [charcalcRecord, setCharCalcRecord] = useState([]);
  const [iToken, setIToken] = useState(200);
  const [oToken, setOToken] = useState(200);
  const [ipage, setIPage] = useState(2);
  const [iCharacter, setICharacter] = useState(100);
  const [oCharacter, setOCharacter] = useState(100);
  const [calcModel, setCalcModel] = useState(props.selectedModels[0]["value"]);
  const [preloaderstatus, setPreloaderStatus] = useState(true);
  const [typelist, setTypeList] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    setPreloaderStatus(true);
    const debounceTimer = setTimeout(() => {
      const records = [];
      setTypeList([]);
      props.selectedModels.map((propsdata) => {
        setTypeList((typelist) => [
          ...typelist,
          propsdata.value.split(",,,")[2],
        ]);
      });
      const loopResult = props.selectedModels.reduce(
        async (previous, value) => {
          await previous;
          // Return new promise
          const input = {
            input_tokens: iToken.toString(),
            output_tokens: oToken.toString(),
            page: ipage.toString(),
            input_character: iCharacter.toString(),
            output_character: oCharacter.toString(),
            provider: value.value.split(",,,")[0],
            model_name: value.value.split(",,,")[1],
            api_type: value.value.split(",,,")[2],
          };
          return new Promise((resolve) => {
            dispatch(apphubcalculator(input))
              .unwrap()
              .then(({ data }) => {
                const record = {
                  itoken: iToken,
                  model: input.model_name,
                  otoken: oToken,
                  pcount: ipage,
                  ochar: oCharacter,
                  ichar: iCharacter,
                  price: JSON.parse(data.token_calculator).total_cost,
                  type: value.value.split(",,,")[2],
                };
                records.push(record);
                resolve();
              })
              .catch((err) => {
                console.log(err);
              });
          });
        },
        Promise.resolve()
      );

      loopResult.then(() => {
        const array = ["llm", "page", "character"];
        const llm_records = records.filter((datas) => datas.type === "llm");
        const page_records = records.filter((datas) => datas.type === "page");
        const character_records = records.filter(
          (datas) => datas.type === "character"
        );
        setCalcRecord(llm_records);
        setPageCalcRecord(page_records);
        setCharCalcRecord(character_records);
        setPreloaderStatus(false);
      });
    }, 500);
    return () => clearTimeout(debounceTimer);
  }, [iToken, oToken, ipage, iCharacter, oCharacter, props.selectedModels]);

  const calcColumns = [
    {
      title: "Model",
      dataIndex: "model",
      key: "model",
      align: "center",
    },
    {
      title: "Input Tokens",
      dataIndex: "itoken",
      key: "itoken",
    },
    {
      title: "Output Tokens",
      dataIndex: "otoken",
      key: "otoken",
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      render: (text) => (text ? `$${text}` : ""),
    },
  ];

  const pageColumns = [
    {
      title: "Model",
      dataIndex: "model",
      key: "model",
      align: "center",
    },
    {
      title: "Page Count",
      dataIndex: "pcount",
      key: "pcount",
    },

    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      render: (text) => (text ? `$${text}` : ""),
    },
  ];

  const charColumns = [
    {
      title: "Model",
      dataIndex: "model",
      key: "model",
      align: "center",
    },
    {
      title: "Input Character",
      dataIndex: "ichar",
      key: "ichar",
    },
    {
      title: "Output Character",
      dataIndex: "ochar",
      key: "ochar",
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      render: (text) => (text ? `$${text}` : ""),
    },
  ];
  return (
    <div>
      <label className=" white-color calculator-header">
        <strong>Pricing Calculation</strong>
      </label>
      <p className="note-text margin-bottom-1">
        The following pricing calculation are based on the input token, output
        token you have entered above. Prices displayed are only a approximate
        value and may not be accurate.
      </p>
      <div>
        <Row className=" margin-bottom-1" gutter={[16, 16]}>
          {typelist.indexOf("llm") !== -1 && (
            <>
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={24}
                xl={24}
                className="text-center calculation-bottom"
              >
                <label className="white-color calculator-header ">
                  Token Calculation
                </label>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Row>
                  <label className="white-color margin-bottom-1">
                    Input Token
                  </label>
                </Row>
                <Row>
                  <InputNumber
                    min={1}
                    max={100000}
                    defaultValue={200}
                    onChange={(val) => {
                      val && setIToken(val);
                    }}
                    className="dashboard-token-input"
                  />
                </Row>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Row>
                  <label className="white-color margin-bottom-1">
                    Output Token
                  </label>
                </Row>
                <Row>
                  <InputNumber
                    min={1}
                    max={100000}
                    defaultValue={200}
                    onChange={(val) => {
                      val && setOToken(val);
                    }}
                    className="dashboard-token-input"
                  />
                </Row>
              </Col>
              <Col span={24}>
                {preloaderstatus ? (
                  <>
                    <Col span={24}>
                      <div className="aivolvex-sub-loader h-18">
                        <img src={preloader} width="20%" />
                      </div>
                    </Col>
                  </>
                ) : (
                  <>
                    {calcRecord.length > 0 && (
                      <>
                        <Table
                          className="recent-prompts-table"
                          dataSource={calcRecord}
                          columns={calcColumns}
                        />
                      </>
                    )}
                  </>
                )}
              </Col>
            </>
          )}
          {typelist.indexOf("character") !== -1 && (
            <>
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={24}
                xl={24}
                className="text-center calculation-bottom"
              >
                <label className="white-color calculator-header ">
                  Character Calculation
                </label>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Row>
                  <label className="white-color margin-bottom-1">
                    Input Character
                  </label>
                </Row>
                <Row>
                  <InputNumber
                    min={1}
                    max={100000}
                    defaultValue={100}
                    onChange={(val) => {
                      val && setICharacter(val);
                    }}
                    className="dashboard-token-input"
                  />
                </Row>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Row>
                  <label className="white-color margin-bottom-1">
                    Output Character
                  </label>
                </Row>
                <Row>
                  <InputNumber
                    min={1}
                    max={100000}
                    defaultValue={100}
                    onChange={(val) => {
                      val && setOCharacter(val);
                    }}
                    className="dashboard-token-input"
                  />
                </Row>
              </Col>
              <Col span={24}>
                {preloaderstatus ? (
                  <>
                    <Col span={24}>
                      <div className="aivolvex-sub-loader h-18">
                        <img src={preloader} width="20%" />
                      </div>
                    </Col>
                  </>
                ) : (
                  <>
                    {charcalcRecord.length > 0 && (
                      <>
                        <Table
                          className="recent-prompts-table"
                          dataSource={charcalcRecord}
                          columns={charColumns}
                        />
                      </>
                    )}
                  </>
                )}
              </Col>
            </>
          )}
          {typelist.indexOf("page") !== -1 && (
            <>
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={24}
                xl={24}
                className="text-center calculation-bottom"
              >
                <label className="white-color calculator-header ">
                  Page Calculation
                </label>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Row>
                  <label className="white-color margin-bottom-1">
                    Page Count
                  </label>
                </Row>
                <Row>
                  <InputNumber
                    min={1}
                    max={100000}
                    defaultValue={2}
                    onChange={(val) => {
                      val && setIPage(val);
                    }}
                    className="dashboard-token-input"
                  />
                </Row>
              </Col>

              <Col span={24}>
                {preloaderstatus ? (
                  <>
                    <Col span={24}>
                      <div className="aivolvex-sub-loader h-18">
                        <img src={preloader} width="20%" />
                      </div>
                    </Col>
                  </>
                ) : (
                  <>
                    {pagecalcRecord.length > 0 && (
                      <>
                        <Table
                          className="recent-prompts-table"
                          dataSource={pagecalcRecord}
                          columns={pageColumns}
                        />
                      </>
                    )}
                  </>
                )}
              </Col>
            </>
          )}
        </Row>
      </div> 
    </div>
  );
};

export default TokenCalculator;
