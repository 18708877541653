import { useState, useEffect } from "react";
import { Col, DatePicker, Row } from "antd";
import "../CSS/dashboardnew.scss";
import ReactApexChart from "react-apexcharts";
import { useDispatch, useSelector } from "react-redux";
import { getcreditavatar, getpromptdaywise } from "../../api/accountAction";
import preloader from "../../../assets/loader/pre_loader.gif";
import NavBar from "../../Header/NavBar";
import Sidemenu from "../../Sidemenu/Sidemenu";
import dayjs from "dayjs";

const Promptusage = () => {
  const [preloaderstatus, setPreloaderStatus] = useState(false);
  const [isPopoverVisible, setIsPopoverVisible] = useState(false);
  const [labelchart, setLabelChart] = useState([]);
  const [numericvalue, setNumericValue] = useState([]);
  const [creditwise, setCreditWise] = useState([]);
  const [labelday, setlabelday] = useState([]);
  const [numbericday, setNumbericday] = useState([]);
  const [threemonthdata, setThreeMonthData] = useState({
    series: [],
    option: [],
  });

  const [dashboardDate, setDashboardDate] = useState(
    `${new Date().getFullYear()}-${(new Date().getMonth() + 1)
      .toString()
      .padStart(2, "0")}-${new Date().getDate().toString().padStart(2, "0")}`
  );
  const handleIconClick = () => {
    setIsPopoverVisible(!isPopoverVisible);
  };
  const dispatch = useDispatch();
  const companyName = atob(
    localStorage.getItem("TokenOptixIdentityServiceProvider.usr")
  );
  const userName = atob(
    localStorage.getItem("TokenOptixIdentityServiceProvider.auth")
  );
  const handleMonthChange = (value) => {
    const dateFormat = "YYYY-MM-DD";
    value
      ? setDashboardDate(value.format(dateFormat))
      : setDashboardDate(
          `${new Date().getFullYear()}-${(new Date().getMonth() + 1)
            .toString()
            .padStart(2, "0")}-${new Date()
            .getDate()
            .toString()
            .padStart(2, "0")}`
        );
  };
  const disabledDate = (current) => {
    return current && current > dayjs().endOf("day");
  };
  const monthFormat = "YYYY/MM";
  const currentThreeMonthDate = new Date(dashboardDate);
  // const year = currentThreeMonthDate.getFullYear();
  // const month = (currentThreeMonthDate.getMonth() + 1)
  //   .toString()
  //   .padStart(2, "0");

  const date = new Date(dashboardDate);

  useEffect(() => {
    const daywise = {
      user_id: userName,
      date_time: dashboardDate.toString(),
    };
    setPreloaderStatus(true);
    dispatch(getpromptdaywise(daywise))
      .unwrap()
      .then(({ data }) => {
        const creditdaywise = JSON.parse(data.daywise_question_token);
        setCreditWise(creditdaywise);
        if (creditdaywise) {
          const dates = creditdaywise.map((item) => item.date_time);
          const numdates = new Date(date.getYear(), date.getMonth() + 1, 0);
          const allDates = Array.from({ length: numdates.getDate() }, (_, i) =>
            String(i + 1).padStart(2, "0")
          );
          const tokensUsed = Array(numdates.getDate()).fill(0);
          allDates.forEach((day, index) => {
            const indexInData = dates.findIndex((date) =>
              date.endsWith(`-${day}`)
            );
            if (indexInData !== -1) {
              tokensUsed[index] = parseInt(
                creditdaywise[indexInData].question_count,
                10
              );
            }
          });
          setlabelday(allDates);
          setNumbericday(tokensUsed);
        } else {
          console.log("No data found.");
        }
        setPreloaderStatus(false);
      })

      .catch((err) => {
        console.log(err);
        setPreloaderStatus(false);
      });

    const input = {
      user_id: userName,
      date_time: dashboardDate.toString(),
    };
    setPreloaderStatus(true);
    dispatch(getcreditavatar(input))
      .unwrap()
      .then(({ data }) => {
        const appprompt = JSON.parse(data.app_wise_question_count);
        var app_list = appprompt.map((item) => item.app_name);
        app_list = new Set([...app_list]);
        app_list = [...app_list];
        const monthNames = [
          "Jan",
          "Feb",
          "March",
          "April",
          "May",
          "June",
          "July",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ];
        //start :get last 3 month
        const lastThreeMonthsDate = new Date(dashboardDate);
        lastThreeMonthsDate.setMonth(currentThreeMonthDate.getMonth() - 2);
        var month_list = [];
        var chart_records = [];
        var chart_app_records = [];
        for (let i = 0; i < 3; i++) {
          let date = new Date(
            lastThreeMonthsDate.getFullYear(),
            lastThreeMonthsDate.getMonth() + i,
            1
          );
          const checkdate = `${date.getFullYear()}-${date.getMonth() + 1}-01`;
          const monthIndex = new Date(checkdate).getMonth();
          const monthName = monthNames[monthIndex];
          app_list.map((appname) => {
            const monthtokenrecords = appprompt.filter((datas) => {
              const inputDate = datas.month;
              const inputDatemonth = inputDate.split("-")[1];
              return (
                datas.app_name?.toLowerCase() === appname?.toLowerCase() &&
                inputDatemonth == monthIndex + 1
              );
            });
            if (monthtokenrecords.length > 0) {
              chart_records.push({
                month: checkdate,
                name: appname,
                data: monthtokenrecords[0].question_count,
              });
            } else {
              chart_records.push({
                month: checkdate,
                name: appname,
                data: 0,
              });
            }
          });
          month_list.push(monthName);
        }
        const chart_app_values = [];
        app_list.map((appname) => {
          const last3month = [];
          for (let i = 0; i < 3; i++) {
            let date = new Date(
              lastThreeMonthsDate.getFullYear(),
              lastThreeMonthsDate.getMonth() + i,
              1
            );
            const checkdate = `${date.getFullYear()}-${date.getMonth() + 1}-01`;
            const monthIndex = new Date(checkdate).getMonth();

            const monthtokenrecords = chart_records.filter((datas) => {
              const inputDate = datas.month; // Example date in "YYYY-MM" format
              const inputDatemonth = inputDate.split("-")[1];
              return (
                datas.name?.toLowerCase() === appname?.toLowerCase() &&
                inputDatemonth == monthIndex + 1
              );
              // return datas.all_cloud?.toLowerCase() === envdata?.toLowerCase();
            });
            if (monthtokenrecords.length > 0) {
              last3month.push(monthtokenrecords[0].data);
            } else {
              last3month.push(0);
            }
          }
          chart_app_values.push({
            name: appname,
            data: last3month,
          });
        });
        setThreeMonthData({
          series: chart_app_values,
          option: month_list,
        });

        const currentDatevalue = new Date(dashboardDate);
        const currentYear = currentDatevalue.getFullYear();
        const currentMonth = (currentDatevalue.getMonth() + 1)
          .toString()
          .padStart(2, "0");
        const currentMonthData = appprompt.filter((item) => {
          const [year, month] = item.month.split("-");
          return year === String(currentYear) && month === currentMonth;
        });

        if (currentMonthData.length > 0) {
          const label_chart = currentMonthData.map((item) => item.app_name);
          setLabelChart(label_chart);
          const month = currentMonthData.map((item) => item.month);
          const chartdata = currentMonthData.map((item) => item.question_count);
          const numericValues_db = chartdata.map((value) =>
            parseInt(value, 10)
          );
          setNumericValue(numericValues_db);
        } else {
          console.log("No apps found.");
        }
        setPreloaderStatus(false);
      })

      .catch((err) => {
        setPreloaderStatus(false);
        console.log(err);
      });
  }, [dashboardDate]);
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const monthName = monthNames[date.getMonth()];

  const promptseries = threemonthdata.series;

  const promptoptions = {
    chart: {
      type: "bar",
      height: 350,
      stacked: true,
      stackType: "100%",
    },
    grid: {
      show: true,
      borderColor: "#42445f",
    },
    plotOptions: {
      bar: {
        columnWidth: "40%",
      },
    },
    legend: {
      show: false,
    },
    title: {
      text: "Last Three Months Prompts",
      align: "left",
      style: {
        color: "#FFFFFF",
        fontWeight: "lighter",
      },
    },
    xaxis: {
      categories: threemonthdata.option,
      labels: {
        style: {
          colors: "#fff",
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          // colors: "#42445f",
          colors: "#fff",
        },
      },
      title: {
        text: "Prompts",
        style: {
          color: "#fff",
        },
      },
    },
    fill: {
      colors: [
        "#1D2B53",
        "#512E5E",
        "#FFF024",
        "#FF004D",
        "#7E2553",
        "#7149C6",
        "#FE6244",
        "#FFDEB9",
        "#F50076",
        "#00CCF5",
        "#FF7700",
        "#FFCF00",
        "#0AFFC2",
        "#006EE9",
      ],
    },
    options: {
      legend: {
        position: "bottom",
        markers: {
          size: 12,
          strokeWidth: 0,
        },
        labels: {
          colors: "#FFFFFF",
        },
      },
    },

    dataLabels: {
      enabled: false,
    },
  };

  const series_db = numericvalue;
  const chartData_db = {
    labels: labelchart,
    series: series_db,
  };

  const options_db = {
    chart: {
      type: "pie",
    },
    labels: labelchart,
    dataLabels: {
      formatter: function (val) {
        return val.toFixed(0) + "%";
      },
    },
    legend: {
      position: "right",
      horizontalAlign: "center",
      show: true,
      position: "right",
      labels: {
        colors: "#FFFFFF",
      },

      markers: {
        width: 13,
        height: 13,
        radius: 4,
        strokeWidth: 0,
      },
    },
    tooltip: {
      enabled: true,
      theme: "light",
      fillSeriesColor: false,
      x: {
        show: false,
      },
      y: {
        formatter: function (val) {
          const billion = 1000000000;
          const million = 1000000;
          const thousand = 1000;

          if (val >= billion) {
            return (val / billion).toFixed(1) + "B";
          } else if (val >= million) {
            return (val / million).toFixed(1) + "M";
          } else if (val >= thousand) {
            return (val / thousand).toFixed(1) + "K";
          } else {
            return val.toString();
          }
          return val;
        },
      },
    },
    colors: [
      "#1D2B53",
      "#512E5E",
      "#FFF024",
      "#FF004D",
      "#7E2553",
      "#7149C6",
      "#FE6244",
      "#FFDEB9",
      "#F50076",
      "#00CCF5",
      "#FF7700",
      "#FFCF00",
      "#0AFFC2",
      "#006EE9",
    ],
    stroke: {
      show: false,
    },
    title: {
      // text: "Current Month Prompts",
      text: `${monthName} Month Prompts`,
      align: "left",
      style: {
        color: "#FFFFFF",
        fontWeight: "lighter",
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          legend: {
            position: "right",
          },
          // legend: {
          //   position: 'bottom',
          // },
        },
      },
    ],
  };

  const options = {
    chart: {
      type: "pie",
    },
    legend: {
      position: "right",
      horizontalAlign: "center",
      labels: {
        colors: "#FFFFFF",
      },
      markers: {
        width: 13,
        height: 13,
        radius: 4,
        strokeWidth: 0,
      },
    },
    tooltip: {
      enabled: true,
      theme: "light",

      x: {
        show: false, // If you don't want to show the x-value
      },
      y: {
        formatter: function (val) {
          const billion = 1000000000;
          const million = 1000000;
          const thousand = 1000;

          if (val >= billion) {
            return (val / billion).toFixed(1) + "B";
          } else if (val >= million) {
            return (val / million).toFixed(1) + "M";
          } else if (val >= thousand) {
            return (val / thousand).toFixed(1) + "K";
          } else {
            return val.toString();
          }
          return val;
        },
      },
    },
    colors: [
      "#1D2B53",
      "#512E5E",
      "#FFF024",
      "#FF004D",
      "#7E2553",
      "#7149C6",
      "#FE6244",
      "#FFDEB9",
      "#F50076",
      "#00CCF5",
      "#FF7700",
      "#FFCF00",
      "#0AFFC2",
      "#006EE9",
    ],
    stroke: {
      show: false,
    },
    title: {
      text: "Current Month Prompt",
      align: "left",
      style: {
        color: "#FFFFFF",
        fontWeight: "lighter",
      },
    },
  };

  const lineseries = [
    {
      name: "Prompt",
      type: "area",
      data: numbericday,
      color: "#72DDF7",
    },
  ];

  const lineoptions = {
    chart: {
      height: 350,
      type: "line",
      zoom: {
        enabled: false,
      },
    },
    grid: {
      show: true,
      borderColor: "#42445f",
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: 2,
    },
    color: ["#fff"],
    title: {
      text: "Prompts By Day",
      align: "left",
      style: {
        fontSize: "14px",
        fontWeight: "bold",
        fontFamily: "DM Sans",
        color: "#fff",
      },
    },
    xaxis: {
      categories: labelday,
      labels: {
        rotate: -45,
        rotateAlways: true,
        style: {
          fontSize: "10px",
          colors: "#ffffff",
        },
        formatter: (num) => {
          const monthNamesShort = [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ];

          const date = new Date(dashboardDate);
          const day = date.getDate();
          const month = monthNamesShort[date.getMonth()];
          const current_month_text = `${num}/${month}`;
          return current_month_text;
        },
      },
      title: {
        text: "Prompts Count",
        style: {
          fontSize: "14px",
          fontWeight: "bold",
          fontFamily: "DM Sans",
          color: "#fff",
        },
      },
      tooltip: {
        enabled: false,
      },
    },
    yaxis: {
      labels: {
        style: {
          // colors: "#42445f",
          colors: "#fff",
        },
        formatter: (value) => {
          const num = (Math.round(value * 100) / 100).toFixed(2);
          return Math.abs(num) > 999
            ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "k"
            : Math.sign(num) * Math.abs(num);
        },
      },
    },
  };

  return (
    <>
      {/* <NavBar /> */}
      <Row>
        <Col xs={0} sm={0} md={3} lg={3} xl={3}>
          <Sidemenu />
        </Col>
        <Col
          xs={24}
          sm={24}
          md={21}
          lg={21}
          xl={21}
          className="aivolvex-dashboard-body"
        >
          <div>
            <NavBar />
          </div>
          <div className="body-margin-top ">
            <Row className="mt-4">
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={12}
                xl={12}
                className="d-flex justify-content-left"
              >
                <h4 className="dashboard-credit aivolvex-font">
                  Prompt Usage Trend
                </h4>
              </Col>
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={12}
                xl={12}
                className="d-flex justify-content-end"
              >
                <DatePicker
                  className="dashboard-datepicker"
                  defaultValue={dayjs(dashboardDate, monthFormat)}
                  format={monthFormat}
                  picker="month"
                  onChange={handleMonthChange}
                  disabledDate={disabledDate}
                />
              </Col>
            </Row>
            {/* <Row className="mt-4">
              <Col span={24} className="d-flex justify-content-center">
                <h4 className="dashboard-credit aivolvex-font">
                  Prompt Usage Trend
                </h4>
              </Col>
            </Row> */}
            <Row
              className="mt-3 d-flex justify-content-between"
              gutter={[16, 16, 16, 16]}
            >
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <div className="aivolvex-dashboard-outline full-width">
                  <div className="Dashboard_graph_box Dashboard_head_box_shadow">
                    <div className="Dashboard_head_content">
                      <div id="chart">
                        {preloaderstatus ? (
                          <>
                            <Col span={24}>
                              <div className="aivolvex-sub-loader h-18">
                                <img src={preloader} width="20%" />
                              </div>
                            </Col>
                          </>
                        ) : (
                          <>
                            <div className="public-agent-desktop">
                              <ReactApexChart
                                options={options_db}
                                series={chartData_db.series}
                                type="pie"
                                height={234}
                              />
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <div className="aivolvex-dashboard-outline full-width">
                  <div className="Dashboard_graph_box Dashboard_head_box_shadow">
                    <div className="Dashboard_head_content">
                      <div id="chart">
                        {preloaderstatus ? (
                          <>
                            <Col span={24}>
                              <div className="aivolvex-sub-loader h-18">
                                <img src={preloader} width="20%" />
                              </div>
                            </Col>
                          </>
                        ) : (
                          <>
                            <ReactApexChart
                              options={promptoptions}
                              series={promptseries}
                              type="bar"
                              height={220}
                              toolbar={false}
                            />
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>

            <Row
              className="mt-3 d-flex justify-content-between"
              gutter={[16, 16, 16, 16]}
            >
              <Col span={24}>
                <div className="aivolvex-dashboard-outline full-width">
                  <div className="Dashboard_graph_box Dashboard_head_box_shadow">
                    <div className="Dashboard_head_content">
                      <div id="chart">
                        {preloaderstatus ? (
                          <>
                            <Col span={24}>
                              <div className="aivolvex-sub-loader h-18">
                                <img src={preloader} width="20%" />
                              </div>
                            </Col>
                          </>
                        ) : (
                          <>
                            <ReactApexChart
                              options={lineoptions}
                              series={lineseries}
                              type="area"
                              height={234}
                            />
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default Promptusage;
