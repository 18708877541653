import { Col, Row, Table } from "antd";
import { memo } from "react";
import { simplifyNumber } from "../../customhook/CustomHoom";
const ApiModelCredits = (props) => {
  // const columns = [
  //   {
  //     title: "Model",
  //     dataIndex: "model",
  //     width: "40%",
  //     render: (text, record) => (
  //       <div style={{ whiteSpace: "nowrap" }}>{text}</div>
  //     ),
  //   },
  //   {
  //     title: "Count",
  //     dataIndex: "call_count",
  //     render: (text) => simplifyNumber(text),
  //     width: "15%",
  //   },
  //   {
  //     title: "Pages",
  //     dataIndex: "page_count",
  //     render: (text) => simplifyNumber(text),
  //     width: "15%",
  //   },
  //   {
  //     title: "Char",
  //     dataIndex: "char_count",
  //     render: (text) => simplifyNumber(text),
  //     width: "15%",
  //   },
  //   {
  //     title: "Share",
  //     dataIndex: "share",
  //     render: (text) => `${text}%`,
  //     width: "15%",
  //   },
  // ];
  // const data = [
  //   {
  //     key: "1",
  //     model: "New York No. 1 Lake Park",
  //     call_count: 32,
  //     page_count: 120006,
  //     char_count: 17.4,
  //     share: 97,
  //   },
  //   {
  //     key: "2",
  //     model: "New York No. 1 Lake Park",
  //     call_count: 72,
  //     page_count: 82,
  //     char_count: 45.4,
  //     share: 17,
  //   },
  // ];
  return (
    <>
       <Row>
        <Col>
          <h1 className="aivolvex-dashboard-model-token model-table-header">
            Model
          </h1>
        </Col>
        <Col span={4}>
          <h1 className="aivolvex-dashboard-model-token aivolvex-right model-table-header d-flex justify-content-center">
            Count
          </h1>
        </Col>
        <Col span={4}>
          <h1 className="aivolvex-dashboard-model-token aivolvex-right model-table-header d-flex justify-content-center">
            Pages
          </h1>
        </Col>
        <Col span={4}>
          <h1 className="aivolvex-dashboard-model-token aivolvex-right model-table-header d-flex justify-content-center">
            Char
          </h1>
        </Col>
        <Col span={4}>
          <h1 className="aivolvex-dashboard-model-token aivolvex-right model-table-header d-flex justify-content-center">
            Share
          </h1>
        </Col>
      </Row>
      <div className="aivolvex-dashboard-modeltoken-scroll">
        {props.modallist.map((result) => {
          return (
            <Row className="mt-2">
              <Col span={8}>
                <label className="aivolvex-dashboard-model-token d-flex ">
                  {result.model}
                </label>
              </Col>
              <Col span={4} className="aivolvex-right">
                <label className="aivolvex-dashboard-model-token d-flex justify-content-center">
                  {simplifyNumber(result.call_count)}
                </label>
              </Col>
              <Col span={4} className="aivolvex-right">
                <label className="aivolvex-dashboard-model-token d-flex justify-content-center">
                  {simplifyNumber(result.page_count)}
                </label>
              </Col>
              <Col span={4} className="aivolvex-right">
                <label className="aivolvex-dashboard-model-token d-flex justify-content-center">
                  {simplifyNumber(result.char_count)}
                </label>
              </Col>
              <Col span={4} className="aivolvex-right">
                <label className="aivolvex-dashboard-model-token d-flex justify-content-center">
                  {result.share}%{" "}
                </label>
              </Col>
            </Row>
          );
        })}
      </div> 
      {/* <div className="api-model-table">
        <Table columns={columns} dataSource={data} size="small" />
      </div> */}
    </>
  );
};

export default memo(ApiModelCredits);
