import "./App.scss";

import React, { useEffect } from "react";
import {
  Route,
  BrowserRouter,
  Routes,
  Navigate,
  useLocation,
} from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import Dashboardfile from "./components/DashboardNew/DashboardFile";
import Login from "./components/Login/Login";

import { Amplify } from "aws-amplify";
import AzureAuth from "./components/Azure_auth/AzureAuth";
import { Authenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import awsExports from "./aws-exports";
import Register from "./components/Login/Register";
import Promptusage from "./components/DashboardNew/Desktop/PromptUsage";
import Usagetrend from "./components/DashboardNew/Desktop/UsageTrend";
import CreditUsage from "./components/DashboardNew/Desktop/CreditUsage";
import UserWiseUsage from "./components/DashboardNew/Desktop/UserWiseUsage";
import Report from "./components/DashboardNew/Desktop/Report";
import Modalinput from "./components/Modal/Modalinput";
import Pricing from "./components/DashboardNew/Desktop/Pricing";
import ApiUsageTrend from "./components/DashboardNew/Desktop/ApiUsageTrend";

Amplify.configure(awsExports);
//components path
const router = [
  {
    path: "/apphub",
    element: <Dashboardfile />,
  },
  {
    path: "/credits",
    element: <CreditUsage />,
  },
  {
    path: "/model",
    element: <Usagetrend />,
  },
  {
    path: "/prompts",
    element: <Promptusage />,
  },
  {
    path: "/price",
    element: <Pricing />,
  },
  {
    path: "/users",
    element: <UserWiseUsage />,
  },
  {
    path: "/report",
    element: <Report />,
  },
  {
    path: "/azure-auth",
    element: <AzureAuth />,
  },
  {
    path: "/demo-model",
    element: <Modalinput />,
  },
  {
    path: "/api",
    element: <ApiUsageTrend />,
  },
];

const ScrollToTop = () => {
  const location = useLocation();
  useEffect(() => {
    if (!location.hash) {
      window.scrollTo({ top: 0, left: 0, behavior: "instant" });
    }
  }, [location]);
};

function App() {
  const userName = localStorage.getItem(
    "TokenOptixIdentityServiceProvider.auth"
  )
    ? atob(localStorage.getItem("TokenOptixIdentityServiceProvider.auth"))
    : "";
  return (
    <div className="App">
      <BrowserRouter>
        <ScrollToTop />
        {userName ? (
          <>
            <Routes>
              {router.map((val) => (
                <Route path={val.path} key={val.path} element={val.element} />
              ))}
              <Route path="*" element={<Navigate replace to="/apphub" />} />
            </Routes>
          </>
        ) : (
          <>
            <Routes>
              <Route path="/" element={<Login />} />
              <Route path="/signup" element={<Register />} />
              <Route path="/azure-auth" element={<AzureAuth />} />
              <Route path="/demo-model" element={<Modalinput />} />
              <Route path="*" element={<Navigate replace to="/" />} />
            </Routes>
          </>
        )}
      </BrowserRouter>
    </div>
  );
}

export default App;
