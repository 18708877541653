import { Col, Row, Table } from "antd";
import { Icon } from "@iconify/react";
import React, { useState, useRef } from "react";
const Curldetails = (props) => {
  const [contentToCopy, setContentToCopy] = useState(false);
  const secondRowContentRef = useRef();
  const graphqlQuery = `query MyQuery {\r\n  token_optix( model_id: \"your_app_model_id\",input_tokens:\"your_input_token\",output_tokens:\"your_output_token\",user_name:\"your_user-name\")
      }`;
  const dataObject = {
    query: graphqlQuery,
    variables: {},
  };
  const dataString = JSON.stringify(dataObject);
  // const [selectedItem, setSelectedItem] = useState("curl");
  // const items = [
  //   {
  //     label: "cURL",
  //     value: "curl",
  //   },
  //   {
  //     label: "JS fetch",
  //     value: "jsfetch",
  //   },
  //   {
  //     label: "Node JS Axios",
  //     value: "nodeaxios",
  //   },
  //   {
  //     label: "Php cURL",
  //     value: "phpcurl",
  //   },
  //   {
  //     label: "Python Request",
  //     value: "pyreq",
  //   },
  //   {
  //     label: "C#",
  //     value: "c#http",
  //   },
  //   {
  //     label: "Java okHttp",
  //     value: "javaokhttp",
  //   },
  // ];
  const handleCopyClick = () => {
    setContentToCopy(true);
    const secondRowContent = secondRowContentRef.current;
    if (secondRowContent) {
      const plainTextContent = secondRowContent.innerText;
      const textarea = document.createElement("textarea");
      textarea.value = plainTextContent;
      document.body.appendChild(textarea);
      textarea.select();
      textarea.setSelectionRange(0, 99999);
      document.execCommand("copy");
      document.body.removeChild(textarea);
    }
  };
  const handleremoveCopyClick = () => {
    setContentToCopy(false);
  };

  const columns = [
    {
      title: "Model",
      dataIndex: "model_name",
      key: "model_name",
      align: "center",
    },
    {
      title: "Model Id",
      dataIndex: "convo_id",
      key: "convo_id",
      align: "center",
    },
  ];

  return (
    <>
      <div>
        {props.editurlstatus ? (
          <></>
        ) : (
          <>
            <Row>
              <Col>
                <Icon
                  icon="teenyicons:tick-circle-solid"
                  className="success-color"
                  style={{ fontSize: "16px" }}
                />
                Registration Completed Successfully!
              </Col>
            </Row>
          </>
        )}

        <br />
        <div className="curl-main-card">
          <Row className="curl-main-card-header">
            {/* <Col>
              <Select
                defaultValue={selectedItem}
                className="recent-search-dropdown"
                onChange={(val) => setSelectedItem(val)}
                options={items}
              />
            </Col> */}
            <Col>
              {contentToCopy ? (
                <>
                  <Icon
                    icon="lucide:copy-check"
                    className="delete-icon cursor-pointer "
                    onClick={handleremoveCopyClick}
                  />
                </>
              ) : (
                <>
                  <Icon
                    icon="clarity:copy-to-clipboard-line"
                    className="delete-icon cursor-pointer "
                    onClick={handleCopyClick}
                  />
                </>
              )}
            </Col>
          </Row>
          <Row>
            <Col span={24} className="p-2 px-3" ref={secondRowContentRef}>
              <p> curl --location 'your_url' \</p>
              <p>--header 'your_header' \</p>
              <p>--header 'your_content_type' \</p>
              <div
                dangerouslySetInnerHTML={{ __html: `--data '${dataString}'` }}
              />
            </Col>
          </Row>
        </div>
        <br />
        <Row>
          <Table
            // className="mt-3 ms-2 costimize-bootstrap-table"
            className="recent-prompts-table"
            dataSource={props.table}
            columns={columns}
            pagination={{ pageSize: 3 }}
            // scroll={{ x: 1500 }}
          />
        </Row>
        <Row>
          <Col>
            Replace "your_url," "your_header," "your_content_type,"
            "your_app_model_id," "your_input_token," "your_output_token," "and "
            your_user-name@example.com " with the actual registration details.
          </Col>
        </Row>
      </div>
    </>
  );
};
export default Curldetails;
