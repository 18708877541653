import {
  Button,
  Col,
  Row,
  Table,
  Pagination,
  Select,
  Input,
  Modal,
} from "antd";
import React, { useEffect, useState, memo } from "react";
import NavBar from "../../Header/NavBar";
import { Icon } from "@iconify/react";
import preloader from "../../../assets/loader/pre_loader.gif";
import { formatDatewithtime } from "../../customhook/CustomHoom";
import Sidemenu from "../../Sidemenu/Sidemenu";
import {
  apphubupdateapps,
  gettokendetails,
  reporttabledata,
  reporttotalpages,
} from "../../api/accountAction";
import { useDispatch } from "react-redux";
import { TbArrowBadgeDownFilled } from "react-icons/tb";
import { AiFillApi } from "react-icons/ai";

function Report() {
  // table pagination
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [count, setCount] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedItem, setSelectedItem] = useState("user_name");
  const [record, setRecord] = useState([]);
  const [apiRecord, setApiRecord] = useState(null);
  const [tokenDetails, setTokenDetails] = useState([
    { input: null, input_tokens: 0 },
    { output: null, output_tokens: 0 },
  ]);
  const [openTokenDetails, setOpenTokenDetails] = useState(false);
  const [openNoDetails, setOpenNoDetails] = useState(false);
  const [openApiDetails, setOpenApiDetails] = useState(false);
  const [preloaderstatus, setPreloaderStatus] = useState(false);
  const [tablepreloaderstatus, setTablePreloaderStatus] = useState(false);

  const companyName = atob(
    localStorage.getItem("TokenOptixIdentityServiceProvider.usr")
  );
  const userName = atob(
    localStorage.getItem("TokenOptixIdentityServiceProvider.auth")
  );

  const dispatch = useDispatch();
  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  const handleItemClick = (item) => {
    // console.log(item);
    setSelectedItem(item);
  };

  const handleNoDataAvailable = (input) => {
    setOpenNoDetails(input);
  };

  const handleApiDetails = (record) => {
    if (record.id) {
      setPreloaderStatus(true);
      const input = {
        id: record.id.toString(),
        api_type: "page",
      };
      dispatch(gettokendetails(input))
        .unwrap()
        .then(({ data }) => {
          if (JSON.parse(data.get_token_details).length > 0) {
            if (
              JSON.parse(data.get_token_details)[0].page_count != null ||
              JSON.parse(data.get_token_details)[0].char_count != null
            ) {
              setApiRecord(JSON.parse(data.get_token_details)[0]);
              setOpenApiDetails(true);
            } else {
              setOpenNoDetails("API");
            }
          } else {
            setOpenNoDetails("API");
          }

          setPreloaderStatus(false);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setOpenNoDetails("API");
    }
  };
  const handleTokenDetails = (record) => {
    if (record.id) {
      setPreloaderStatus(true);
      const input = {
        id: record.id.toString(),
        api_type: "llm",
      };
      dispatch(gettokendetails(input))
        .unwrap()
        .then(({ data }) => {
          if (JSON.parse(data.get_token_details).length > 0) {
            setTokenDetails(JSON.parse(data.get_token_details));
            setOpenTokenDetails(true);
          } else {
            setOpenNoDetails("Token");
          }
          setPreloaderStatus(false);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setOpenNoDetails("Token");
    }
  };

  useEffect(() => {
    // console.log(inputlist);
    const debounceTimer = setTimeout(() => {
      // console.log("API call with search term:", searchQuery);
      const input = {
        limit: pageSize,
        page: currentPage,
        drop_down: selectedItem,
        text: searchQuery,
      };
      setTablePreloaderStatus(true);
      dispatch(reporttabledata(input))
        .unwrap()
        .then(({ data }) => {
          const value = JSON.parse(data.recent_prompt);
          const table = value.map((datas) => {
            return {
              dateandtime: datas.timestamp,
              price: datas.total_cost,
              email: datas.user_name,
              app_name: datas.app_name,
              id: datas.user_session,
              api_type: datas.api_type,
              api_cost: datas.api_cost ? datas.api_cost : 0,
            };
          });
          setRecord(table);
          setTablePreloaderStatus(false);
        })
        .catch((err) => {
          console.log(err);
          setTablePreloaderStatus(false);
        });
    }, 500);
    return () => clearTimeout(debounceTimer);
  }, [searchQuery, selectedItem, currentPage, pageSize]);

  useEffect(() => {
    const debounceTimer = setTimeout(() => {
      const input = {
        drop_down: selectedItem,
        text: searchQuery,
      };
      dispatch(reporttotalpages(input))
        .unwrap()
        .then(({ data }) => {
          const value = JSON.parse(data.pageno_recent);
          setCount(value);
        })
        .catch((err) => {
          console.log(err);
        });
      setCurrentPage(1);
    }, 500);
    return () => clearTimeout(debounceTimer);
  }, [searchQuery, selectedItem]);

  const items = [
    {
      label: "User Name",
      value: "user_name",
    },
    {
      label: "Application Name",
      value: "app_name",
    },
  ];

  const columns = [
    {
      title: "Date and Time",
      dataIndex: "dateandtime",
      key: "dateandtime",
      align: "center",
      render: (text) => formatDatewithtime(text),
    },
    {
      title: "Application Name",
      dataIndex: "app_name",
      key: "app_name",
      align: "center",
    },
    // {
    //   title: "Model ID",
    //   dataIndex: "model_id",
    //   key: "model_id",
    //   align: "center",
    // },
    {
      title: "User Name",
      dataIndex: "email",
      key: "email",
      align: "center",
    },
    // {
    //   title: "Model Name",
    //   dataIndex: "model_name",
    //   key: "model_name",
    //   align: "center",
    // },
    {
      title: "Token Details",
      dataIndex: "token_details",
      key: "itoken",
      align: "center",
      render: (text, record) => (
        <div className="description-popup">
          <TbArrowBadgeDownFilled
            className="cursor-pointer description-popup-icon"
            style={{ fontSize: "28px" }}
            onClick={() => {
              handleTokenDetails(record);
            }}
          />
        </div>
      ),
    },
    {
      title: "Token Price",
      dataIndex: "price",
      key: "price",
      align: "center",
      render: (text) => (text ? `$${text}` : `$0`),
    },
    {
      title: "API Details",
      dataIndex: "token_details",
      key: "itoken",
      align: "center",
      render: (text, record) => (
        <div className="description-popup">
          <AiFillApi
            className="cursor-pointer description-popup-icon"
            style={{ fontSize: "28px" }}
            onClick={() => {
              handleApiDetails(record);
            }}
          />
        </div>
      ),
    },
    {
      title: "API Price",
      dataIndex: "api_cost",
      key: "api_cost",
      align: "center",
      render: (text) => (text ? `$${text}` : `$0`),
    },
  ];

  return (
    <>
     <Row>
        <Col xs={0} sm={0} md={3} lg={3} xl={3}>
          <Sidemenu />
        </Col>
        <Col
          xs={24}
          sm={24}
          md={21}
          lg={21}
          xl={21}
          className="aivolvex-dashboard-body"
        >
          <div>
            <NavBar />
          </div>
          <div className="body-margin-top">
            <div className="dashboard-table-body">
              <Col span={24} className=" recent-body">
                <Row>
                  <Col
                    xs={24}
                    sm={24}
                    md={24}
                    lg={12}
                    xl={12}
                    className="d-flex justify-content-left"
                  >
                    <h4 className="dashboard-credit aivolvex-font d-flex align-items-center">
                      Report
                    </h4>
                  </Col>
                  <Col
                    xs={24}
                    sm={24}
                    md={24}
                    lg={12}
                    xl={12}
                    className="d-flex justify-content-end"
                  >
                    <Row className="recent-prompt-search">
                      <div className="recent-search">
                        <Col>
                          <Select
                            defaultValue={selectedItem}
                            className="recent-search-dropdown"
                            onChange={handleItemClick}
                            options={items}
                          />
                        </Col>
                        <Col className="d-flex align-items-center">
                          <Input
                            placeholder="Search"
                            suffix={
                              <Icon
                                icon="material-symbols:search"
                                style={{ fontSize: "20px" }}
                              />
                            }
                            className="recent-search-input"
                            onChange={(e) => setSearchQuery(e.target.value)}
                            value={searchQuery}
                          />
                        </Col>
                      </div>
                    </Row>
                  </Col>
                  <Col
                    xs={24}
                    sm={24}
                    md={24}
                    lg={12}
                    xl={12}
                    className="d-flex justify-content-end"
                  ></Col>
                </Row>
                <Row>
                  {tablepreloaderstatus && !openTokenDetails ? (
                    <>
                      <Col span={24}>
                        <div className="aivolvex-sub-loader h-18">
                          <img src={preloader} width="20%" />
                        </div>
                      </Col>
                    </>
                  ) : (
                    <>
                      <Col span={24} className="recent-prompt-table">
                        <Table
                          className="recent-prompts-table"
                          dataSource={record}
                          columns={columns}
                          // scroll={{ x: 1500 }}
                          pagination={{
                            current: currentPage,
                            pageSize: pageSize,
                            total: count,
                            onChange: handlePageChange,
                          }}
                        />
                      </Col>
                    </>
                  )}
                </Row>
              </Col>
            </div>
          </div>
        </Col>
      </Row>

      {/* modals */}
      <Modal
        open={openTokenDetails}
        footer={false}
        onCancel={() => {
          setPreloaderStatus(false);
          setOpenTokenDetails(false);
          setTokenDetails([
            { input: null, input_tokens: 0 },
            { output: null, output_tokens: 0 },
          ]);
        }}
        width={"60%"}
      >
        <>
          {preloaderstatus ? (
            <>
              <Col span={24}>
                <div className="aivolvex-sub-loader h-18">
                  <img src={preloader} width="20%" />
                </div>
              </Col>
            </>
          ) : (
            <>
              <Row className="token-details-header">Token Details</Row>
              <Row className="token-details-content">
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Row className="token-details-subheader">Model Name </Row>
                  <Row>
                    <div className="token-details-question-model">
                      {tokenDetails[0].model_name ? (
                        tokenDetails[0].model_name
                      ) : (
                        <></>
                      )}
                    </div>
                  </Row>
                </Col>
                <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                  <Row className="token-details-subheader">Question</Row>
                  <Row>
                    <div className="token-details-question">
                      {tokenDetails[0].input ? tokenDetails[0].input : <></>}
                    </div>
                  </Row>
                  <div className="input-tokens">
                    {tokenDetails[0].input_tokens ? (
                      tokenDetails[0].input_tokens
                    ) : (
                      <>0</>
                    )}
                  </div>
                </Col>
                <Col xs={24} sm={24} md={24} lg={18} xl={18}>
                  <Row className="token-details-subheader">Answer</Row>
                  <Row>
                    <div className="token-details-answer">
                      {tokenDetails[1].output ? tokenDetails[1].output : <></>}
                    </div>
                  </Row>
                  <Row>
                    <div className="output-tokens">
                      {tokenDetails[1].output_tokens ? (
                        tokenDetails[1].output_tokens
                      ) : (
                        <>0</>
                      )}
                    </div>
                  </Row>
                </Col>
              </Row>
            </>
          )}
        </>
      </Modal>
      <Modal
        open={openNoDetails === "Token" || openNoDetails === "API"}
        footer={false}
        onCancel={() => {
          setPreloaderStatus(false);
          setOpenNoDetails(false);
        }}
        width={"60%"}
      >
        <>
          <Row className="token-details-header">{openNoDetails} Details</Row>
          <Row className="token-details-content">
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Row className="token-details-answer d-flex justify-content-center align-items-center">
                No Data Available
              </Row>
              <Row>
                {/* <div className="token-details-question">
                  {tokenDetails[0].input ? tokenDetails[0].input : <></>}
                </div> */}
              </Row>
            </Col>
          </Row>
        </>
      </Modal>
      <Modal
        open={openApiDetails}
        footer={false}
        onCancel={() => {
          setPreloaderStatus(false);
          setOpenApiDetails(false);
          setApiRecord(null);
        }}
        width={"60%"}
      >
        <>
          <Row className="token-details-header">Api Details</Row>

          <>
            {apiRecord && (
              <>
                <Row className="api-details-content">
                  <Col span={24}>
                    <Row>
                      <Col
                        className="api-details-subheader"
                        xs={8}
                        sm={8}
                        md={8}
                        lg={8}
                        xl={8}
                      >
                        Model Name :
                      </Col>
                      <Col
                        className="api-details-question"
                        xs={8}
                        sm={8}
                        md={8}
                        lg={8}
                        xl={8}
                      >
                        {apiRecord.model_name}
                      </Col>
                    </Row>
                    {apiRecord.page_count != 0 && (
                      <Row>
                        <Col
                          className="api-details-subheader"
                          xs={8}
                          sm={8}
                          md={8}
                          lg={8}
                          xl={8}
                        >
                          Document AI - Pages :
                        </Col>
                        <Col
                          className="api-details-question"
                          xs={8}
                          sm={8}
                          md={8}
                          lg={8}
                          xl={8}
                        >
                          <div className="output-tokens">
                            {apiRecord.page_count || 0}
                          </div>
                        </Col>
                      </Row>
                    )}
                    {apiRecord.char_count != 0 && (
                      <Row>
                        <Col
                          className="api-details-subheader"
                          xs={8}
                          sm={8}
                          md={8}
                          lg={8}
                          xl={8}
                        >
                          AWS Translate - Characters :
                        </Col>
                        <Col
                          className="api-details-question "
                          xs={8}
                          sm={8}
                          md={8}
                          lg={8}
                          xl={8}
                        >
                          <div className="input-tokens">
                            {apiRecord.char_count || 0}
                          </div>
                        </Col>
                      </Row>
                    )}
                  </Col>
                </Row>
              </>
            )}
          </>
        </>
      </Modal>
    </>
  );
}

export default memo(Report);
