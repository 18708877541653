import React, { useState, useEffect, memo } from "react";
import { Col, Row, Popconfirm, DatePicker } from "antd";
import "../CSS/dashboardnew.scss";
import ReactApexChart from "react-apexcharts";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import {
  apigetmodalchart,
  apigettokenagent,
  apigettopuser,
  apigetyearusage,
} from "../../api/accountAction";
import ModelCredits from "./ModelCredits";
import { Icon } from "@iconify/react";
import { simplifyNumber } from "../../customhook/CustomHoom";
import preloader from "../../../assets/loader/pre_loader.gif";
import Sidemenu from "../../Sidemenu/Sidemenu";
import NavBar from "../../Header/NavBar";
import ApiModelCredits from "./ApiModelCredits";

const ApiUsageTrend = () => {
  const dispatch = useDispatch();
  const [preloaderstatus, setPreloaderStatus] = useState(false);
  const companyName = atob(
    localStorage.getItem("TokenOptixIdentityServiceProvider.usr")
  );
  const userName = atob(
    localStorage.getItem("TokenOptixIdentityServiceProvider.auth")
  );

  const [labelchart, setLabelChart] = useState([]);
  const [numericvalue, setNumericValue] = useState([]);
  const [labelagent, setLabelAgent] = useState([]);
  const [numericagent, setNumericAgent] = useState([]);
  const [labeltopuser, setLabelTopUser] = useState([]);
  const [modallist, setModallist] = useState([]);
  const [numerictopuser, setNumericTopUser] = useState([]);
  const [labelyearusage, setLabelYearUsage] = useState([]);
  const [allocatedtoken, setAllocatedtoken] = useState([]);
  const [dashboardDate, setDashboardDate] = useState(
    `${new Date().getFullYear()}-${(new Date().getMonth() + 1)
      .toString()
      .padStart(2, "0")}-${new Date().getDate().toString().padStart(2, "0")}`
  );
  const date = new Date(dashboardDate);
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const handleMonthChange = (value) => {
    const dateFormat = "YYYY-MM-DD";
    value
      ? setDashboardDate(value.format(dateFormat))
      : setDashboardDate(
          `${new Date().getFullYear()}-${(new Date().getMonth() + 1)
            .toString()
            .padStart(2, "0")}-${new Date()
            .getDate()
            .toString()
            .padStart(2, "0")}`
        );
  };
  const disabledDate = (current) => {
    return current && current > dayjs().endOf("day");
  };
  const monthFormat = "YYYY/MM";
  useEffect(() => {
    const appmonthwise = {
      user_id: userName,
      date_time: dashboardDate.toString(),
    };
    setPreloaderStatus(true);
    dispatch(apigettokenagent(appmonthwise))
      .unwrap()
      .then(({ data }) => {
        const appcredit = JSON.parse(data.api_token_month_wise);
        const currentDateValue = new Date(dashboardDate);
        const currentYear = currentDateValue.getFullYear();
        const currentMonth = (currentDateValue.getMonth() + 1)
          .toString()
          .padStart(2, "0");

        const currentMonthData = appcredit.filter((item) => {
          const [year, month] = item.month.split("-");
          return year === String(currentYear) && month === currentMonth;
        });

        if (currentMonthData) {
          currentMonthData.sort(
            (a, b) => b.total_token_used - a.total_token_used
          );
          const top5Data = currentMonthData.slice(0, 5);
          const label_chart = top5Data.map((item) => item.app_name);
          setLabelAgent(label_chart);
          const chartdata = top5Data.map((item) => item.total_token_used);
          const numericValues_db = chartdata.map((value) =>
            parseInt(value, 10)
          );
          setNumericAgent(numericValues_db);
        }
        setPreloaderStatus(false);
      })
      .catch((err) => {
        console.log(err);
        setPreloaderStatus(false);
      });
    const top5user = {
      user_id: userName,
      year: year,
      month: month,
    };
    setPreloaderStatus(true);
    dispatch(apigettopuser(top5user))
      .unwrap()
      .then(({ data }) => {
        const gettopuser = JSON.parse(data.top_user_token);
        const label_chart = gettopuser.map((item) => item.user_id);
        setLabelTopUser(label_chart);
        const chartdata = gettopuser.map((item) => item.total_tokens);
        const numericValues_db = chartdata.map((value) => parseInt(value, 10));
        setNumericTopUser(numericValues_db);
        setPreloaderStatus(false);
      })
      .catch((err) => {
        console.log(err);
        setPreloaderStatus(false);
      });

    const input = {
      user_id: userName,
      year: year,
    };
    setPreloaderStatus(true);
    dispatch(apigetyearusage(input))
      .unwrap()
      .then(({ data }) => {
        const getyearusage = JSON.parse(data.api_year_total_count);
        console.log(getyearusage);
        const monthNames = [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ];

        const allMonthsData = Array.from({ length: 12 }, (_, i) => {
          // console.log(i);
          const monthData = getyearusage.find(
            (item) => parseInt(item.month, 10) === i + 1
          );
          return monthData ? monthData : { month: i + 1, call_count: 0 };
        });
        // console.log(allMonthsData);
        allMonthsData.sort((a, b) => (a.month > b.month ? 1 : -1));

        const labelYearUsage = allMonthsData.map(
          (item) => monthNames[item.month - 1]
        );

        setLabelYearUsage(labelYearUsage);
        const tokenused = allMonthsData.map((item) =>
          parseInt(item.call_count, 10)
        );
        setAllocatedtoken(tokenused);
        setPreloaderStatus(false);
      })
      .catch((err) => {
        console.log(err);
        setPreloaderStatus(false);
      });

    const questioncount = {
      user_id: userName,
      date_time: dashboardDate.toString(),
    };
    setPreloaderStatus(true);
    dispatch(apigetmodalchart(questioncount))
      .unwrap()
      .then(({ data }) => {
        const modalquestion = JSON.parse(data.api_model_wise_question_count);
        console.log(modalquestion);
        const total_sum = modalquestion.reduce(
          (total, num) => +total + +num.call_count,
          0
        );
        var final_result = [];
        if (modalquestion.length > 0) {
          modalquestion.map((model_data) => {
            final_result.push({
              model: model_data.model_name,
              call_count: model_data.call_count,
              page_count: model_data.total_pg_num,
              char_count: model_data.total_char_count,
              share: Math.round((model_data.call_count * 100) / total_sum),
            });
          });
        }
        setModallist(final_result);
        if (modalquestion) {
          const label_chart = modalquestion.map((item) => {
            if (item.model_name) {
              return item.model_name;
            } else {
              return "empty";
            }
          });
          setLabelChart(label_chart);

          const chartdata = modalquestion.map((item) => item.call_count);
          const numericValues_db = chartdata.map((value) =>
            parseInt(value, 10)
          );
          setNumericValue(numericValues_db);
        } else {
          console.log("No apps found.");
        }
        setPreloaderStatus(false);
      })

      .catch((err) => {
        console.log(err);
        setPreloaderStatus(false);
      });
  }, [dashboardDate]);

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const monthName = monthNames[date.getMonth()];

  const options_db = {
    chart: {
      type: "pie",
    },
    labels: labelchart,
    dataLabels: {
      formatter: function (val) {
        return val.toFixed(0) + "%";
      },
    },
    legend: {
      position: "right",
      horizontalAlign: "center",
      show: true,
      position: "right",
      labels: {
        colors: "#FFFFFF",
      },

      markers: {
        width: 13,
        height: 13,
        radius: 4,
        strokeWidth: 0,
      },
    },
    tooltip: {
      enabled: true,
      theme: "light",
      fillSeriesColor: false,
      y: {
        formatter: function (val) {
          const billion = 1000000000;
          const million = 1000000;
          const thousand = 1000;

          if (val >= billion) {
            return (val / billion).toFixed(1) + "B";
          } else if (val >= million) {
            return (val / million).toFixed(1) + "M";
          } else if (val >= thousand) {
            return (val / thousand).toFixed(1) + "K";
          } else {
            return val.toString();
          }
          return val;
        },
      },
    },
    colors: [
      "#1D2B53",
      "#512E5E",
      "#FFF024",
      "#FF004D",
      "#7E2553",
      "#7149C6",
      "#FE6244",
      "#FFDEB9",
      "#F50076",
      "#00CCF5",
      "#FF7700",
      "#FFCF00",
      "#0AFFC2",
      "#006EE9",
    ],
    stroke: {
      show: false,
    },
    title: {
      text: `${monthName} Month Model Calls`,
      align: "left",
      style: {
        color: "#FFFFFF",
        fontWeight: "lighter",
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };

  const seriestopuser = numerictopuser;
  const chartDataTopUser = {
    labels: labelchart,
    series: seriestopuser,
  };
  const topuseroptions = {
    chart: {
      type: "pie",
    },
    labels: labeltopuser,
    dataLabels: {
      formatter: function (val) {
        return val.toFixed(0) + "%";
      },
    },
    legend: {
      position: "right",
      horizontalAlign: "center",
      show: true,
      position: "right",
      labels: {
        colors: "#FFFFFF",
      },

      markers: {
        width: 13,
        height: 13,
        radius: 4,
        strokeWidth: 0,
      },
    },
    tooltip: {
      enabled: true,
      theme: "light",
      fillSeriesColor: false,
      y: {
        formatter: function (val) {
          const billion = 1000000000;
          const million = 1000000;
          const thousand = 1000;

          if (val >= billion) {
            return (val / billion).toFixed(1) + "B";
          } else if (val >= million) {
            return (val / million).toFixed(1) + "M";
          } else if (val >= thousand) {
            return (val / thousand).toFixed(1) + "K";
          } else {
            return val.toString();
          }
          return val;
        },
      },
    },
    colors: [
      "#1D2B53",
      "#512E5E",
      "#FFF024",
      "#FF004D",
      "#7E2553",
      "#7149C6",
      "#FE6244",
      "#FFDEB9",
      "#F50076",
      "#00CCF5",
      "#FF7700",
      "#FFCF00",
      "#0AFFC2",
      "#006EE9",
    ],
    stroke: {
      show: false,
    },
    title: {
      text: `${monthName} Month Top 5 Users by Tokens`,
      align: "left",
      style: {
        color: "#FFFFFF",
        fontWeight: "lighter",
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };

  const series_db = numericvalue;
  const chartData_db = {
    labels: labelchart,
    series: series_db,
  };

  const series = [44, 55, 41, 17, 15];

  const userseries = numericagent;

  const chartdata = {
    labels: labelagent,
    series: userseries,
  };

  const useroptions = {
    chart: {
      type: "pie",
    },
    labels: labelagent,
    dataLabels: {
      formatter: function (val) {
        return val.toFixed(0) + "%";
      },
    },
    legend: {
      position: "right",
      horizontalAlign: "center",
      show: true,
      position: "right",
      labels: {
        colors: "#FFFFFF",
      },

      markers: {
        width: 13,
        height: 13,
        radius: 4,
        strokeWidth: 0,
      },
    },
    tooltip: {
      enabled: true,
      theme: "light",
      fillSeriesColor: false,
      y: {
        formatter: function (val) {
          const billion = 1000000000;
          const million = 1000000;
          const thousand = 1000;

          if (val >= billion) {
            return (val / billion).toFixed(1) + "B";
          } else if (val >= million) {
            return (val / million).toFixed(1) + "M";
          } else if (val >= thousand) {
            return (val / thousand).toFixed(1) + "K";
          } else {
            return val.toString();
          }
          return val;
        },
      },
    },
    colors: [
      "#1D2B53",
      "#512E5E",
      "#FFF024",
      "#FF004D",
      "#7E2553",
      "#7149C6",
      "#FE6244",
      "#FFDEB9",
      "#F50076",
      "#00CCF5",
      "#FF7700",
      "#FFCF00",
      "#0AFFC2",
      "#006EE9",
    ],
    stroke: {
      show: false,
    },
    title: {
      text: `${monthName}  Month Top 5 Agent by Tokens`,
      align: "left",
      style: {
        color: "#FFFFFF",
        fontWeight: "lighter",
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };

  const lineseries = [
    {
      name: "Calls",
      type: "area",
      data: allocatedtoken,
      color: "#9dd64d",
    },
  ];
  const lineoptions = {
    chart: {
      height: 350,
      type: "line",
      toolbar: {
        show: false,
      },
    },
    series: series,
    grid: {
      show: true,
      borderColor: "#42445f",
    },
    stroke: {
      curve: "smooth",
      width: 2,
    },
    title: {
      align: "left",
      style: {
        color: "#fff",
        fontWeight: "lighter",
      },
    },
    fill: {
      type: "solid",
      opacity: [0.35, 1],
    },
    labels: labelyearusage,
    markers: {
      size: 0,
    },
    // yaxis: [
    //   {
    //     title: {
    //       text: "Token",
    //       style: {
    //         color: "#ffffff",
    //       },
    //     },
    //     labels: {
    //       style: {
    //         colors: "#42445f",
    //       },
    //     },
    //   },
    // ],
    yaxis: {
      labels: {
        style: {
          // colors: "rgba(255, 255, 255, 0.40)",
          colors: "#fff",
        },
        formatter: (value) => {
          const num = (Math.round(value * 100) / 100).toFixed(2);
          const absNum = Math.abs(num);

          if (absNum >= 1e9) {
            return Math.sign(num) * (absNum / 1e9).toFixed(1) + "B";
          } else if (absNum >= 1e6) {
            return Math.sign(num) * (absNum / 1e6).toFixed(1) + "M";
          } else if (absNum > 999) {
            return Math.sign(num) * (absNum / 1000).toFixed(1) + "K";
          } else {
            return Math.sign(num) * absNum;
          }
        },
      },
    },

    legend: {
      labels: {
        colors: "#ffffff",
      },
    },
    xaxis: {
      title: {
        //   text: 'Month',
        style: {
          color: "#ffffff",
        },
      },
      labels: {
        style: {
          colors: "#ffffff",
        },
      },
      tooltip: {
        enabled: false,
      },
    },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: function (y) {
          if (typeof y !== "undefined") {
            return y.toFixed(0);
          }
          return y;
        },
      },
    },
  };

  return (
    <>
      {/* <NavBar /> */}
      <Row>
        <Col xs={0} sm={0} md={3} lg={3} xl={3}>
          <Sidemenu />
        </Col>
        <Col
          xs={24}
          sm={24}
          md={21}
          lg={21}
          xl={21}
          className="aivolvex-dashboard-body"
        >
          <div>
            <NavBar />
          </div>
          <div className="body-margin-top ">
            <Row className="mt-4">
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={12}
                xl={12}
                className="d-flex justify-content-left"
              >
                <h4 className="dashboard-credit aivolvex-font">
                  API Usage Trend
                </h4>
              </Col>
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={12}
                xl={12}
                className="d-flex justify-content-end"
              >
                <DatePicker
                  className="dashboard-datepicker"
                  defaultValue={dayjs(dashboardDate, monthFormat)}
                  format={monthFormat}
                  picker="month"
                  onChange={handleMonthChange}
                  disabledDate={disabledDate}
                />
              </Col>
            </Row>
            {/* <Row className="mt-4">
              <Col span={24} className="d-flex justify-content-center">
                <h4 className="dashboard-credit aivolvex-font">
                  Model Usage Trend
                </h4>
              </Col>
            </Row> */}

            {/* <Row>
        <Col span={24} className="d-flex justify-content-center mt-4">
          <h4 className="white-color dashboard-sub-header">Usage Trend</h4>
        </Col>
      </Row> */}
            <Row
              className="mt-3 d-flex justify-content-between"
              gutter={[16, 16]}
            >
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <div className="aivolvex-dashboard-outline full-width">
                  <div className="Dashboard_graph_box Dashboard_head_box_shadow">
                    <div className="Dashboard_head_content">
                      <div id="chart">
                        {preloaderstatus ? (
                          <>
                            <Col span={24}>
                              <div className="aivolvex-sub-loader h-18">
                                <img src={preloader} width="20%" />
                              </div>
                            </Col>
                          </>
                        ) : (
                          <>
                            <ReactApexChart
                              options={options_db}
                              series={chartData_db.series}
                              type="pie"
                              height={234}
                            />
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <div className="aivolvex-dashboard-outline full-width">
                  <div className="Dashboard_graph_box Dashboard_head_box_shadow">
                    <div className="Dashboard_head_content">
                      <div className="table-usage">
                        <>
                          <ApiModelCredits modallist={modallist} />
                        </>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>

            <Row
              className="mt-3 d-flex justify-content-between"
              gutter={[16, 16]}
            >
              <Col span={24}>
                <div className="aivolvex-dashboard-outline full-width">
                  <div className="Dashboard_graph_box Dashboard_head_box_shadow">
                    <div className="Dashboard_head_content">
                      <div id="chart">
                        {preloaderstatus ? (
                          <>
                            <Col span={24}>
                              <div className="aivolvex-sub-loader h-18">
                                <img src={preloader} width="20%" />
                              </div>
                            </Col>
                          </>
                        ) : (
                          <>
                            <ReactApexChart
                              options={lineoptions}
                              series={lineseries}
                              type="line"
                              height={234}
                            />
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </>
  );
};

const PopConfirmTemplate = (props) => {
  return (
    <>
      <div className="volvex-shared-form">
        <p className="aivolvex-dashboard-popconfirm-content white-color">
          {props.content}
        </p>
      </div>
    </>
  );
};

export default memo(ApiUsageTrend);
